import React from 'react'
import PropTypes from 'prop-types'
import Button from 'react-bootstrap/Button'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SocialMediaButton = props => (
  <a href={props.href || window.location.href} target="_blank" rel="noopener noreferrer">
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={subProps => (
        <Tooltip {...subProps}>
          {props.tooltipText}
        </Tooltip>
      )}
    >
      <Button className={"icon " + props.className} size="sm">
        <FontAwesomeIcon icon={props.icon}/>
      </Button>
    </OverlayTrigger>
  </a>
)

SocialMediaButton.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.object,
  tooltipText: PropTypes.string,
}

export default SocialMediaButton
