import React from 'react'
import PropTypes from 'prop-types'
import SocialMediaButton from "../SocialMediaButton"
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import "./email.scss"

const EmailButton = props => (
  <SocialMediaButton
    className="email"
    href={props.href}
    icon={faEnvelope}
    tooltipText="Share via Email!"
  />
)

EmailButton.propTypes = {
  href: PropTypes.string.isRequired,
}

export default EmailButton
